import { useQuery } from '@tanstack/vue-query'
import { getCheckoutConfigsQuery } from '../queries/getCheckoutConfigsQuery'

export const useConfig = () => {
  const { data, isPending } = useQuery({
    ...getCheckoutConfigsQuery(),
  })

  const fromPrice = computed(() => {
    const plans = data.value?.plans

    // Get the first plan from the list
    const plan = plans?.[0]

    if (!plan) {
      return 0
    }

    return plan.price / plan.maxInstallments
  })

  return {
    data,
    isPending,
    fromPrice,
  }
}
