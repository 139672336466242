import { queryOptions } from '@tanstack/vue-query'
import { useApi } from '~/domains/global/composables/useApi'

export const checkCouponQuery = (couponId: string) => {
  return queryOptions({
    queryKey: ['checkCoupon', couponId],
    queryFn: async () => {
      const api = useApi()
      const data = await api<Coupon>('/billing/coupon/' + couponId, {
        method: 'GET',
      })

      return data
    },
    staleTime: 1000 * 60 * 60,
  })
}

export type Coupon = {
  id: string
  coupon: string
  percentage: number
}
